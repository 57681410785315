import { FlexBox, theme, trackEvent, TextField } from '@rario/shared-components'
import { useEffect, useRef, useState, Fragment } from 'react'
import { Sidenav } from './Header.styles'
import WalletBalanceBox from '../RarioWallet/WalletBalanceBox'
import LeftArrowIcon from 'assets/svg/LeftArrowIcon'
import Link from 'next/link'
import useD3Link from 'hooks/useD3Link'
import TrustedBy from 'components/TrustedBy'
import { MOBILE_ROUTES } from './MenuListData'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { IsoCodes, SideMenuItemsEnum, ThemeEnum } from 'interfaces'
import { getAppWebViewDataFromLocalStorage, getFormattedEventName } from 'utils/utils'
import { trackMenuClicks } from 'utils/trackEventUtils'
import { MenuCurrencyToggle } from 'components/CurrencyToggle/MenuCurrencyToogle'
import MobileHeaderItem from './MobileHeaderItem'
import useOnClickOutside from 'helpers/hooks/useClickOutSide.hooks'
import LogoutButton from 'components/LogoutButton'
import { PLATFORM_ORIGIN } from 'config'
// import { getPlatformName } from 'utils/platform'

const MobileHeader: React.FunctionComponent<{
  isMenuOpen: boolean
  handleMobileMenu: Function
}> = ({ isMenuOpen, handleMobileMenu }) => {
  const { user } = useCustomUser()
  const mobileAppBaseConfigData = getAppWebViewDataFromLocalStorage()
  const SidenavRef = useRef<HTMLDivElement>(null)
  const [showWalletBox, setShowWalletBox] = useState(false)

  useOnClickOutside(SidenavRef, () => handleMobileMenu(false))

  useEffect(() => {
    if (!showWalletBox && isMenuOpen) {
      setShowWalletBox(true)
    }
    if (isMenuOpen) {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'homepage-more_menu_viewed',
        view_type: 'constrained',
        type: 'side_bar',
        category: 'menu',
      })
    }

    const body: HTMLBodyElement | null = document.querySelector('body')
    if (isMenuOpen) {
      window?.scrollY === 0 && window?.scrollBy(0, 6)
      body?.classList.add('noscroll')
    }
    return () => {
      body?.classList.remove('noscroll')
    }
  }, [isMenuOpen])

  const collapseAllMenus = () => {
    handleMobileMenu(false)
  }

  const getActiveIcon = (Component: React.ReactElement) => {
    return <Component.type {...Component.props} opacity={1} fill={theme.colors.secondary} />
  }

  const handleNavMenuClick = (url: string) => {
    const eventName = getFormattedEventName(url)
    trackMenuClicks({
      sub_category: `homepage-menu_${eventName}`,
      text_url: url,
    })
    collapseAllMenus()
    window?.ReactNativeWebView?.postMessage('custom_click')
  }

  // TODO: Manage via above func
  const handelNewWindownclick = (name: string, url: string) => {
    trackMenuClicks({
      sub_category: `homepage-menu_${name}_new_window`,
      text_url: url,
    })
    collapseAllMenus()

    if (mobileAppBaseConfigData) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'traget_blank', url }))
    } else {
      window?.open(url, '_blank')
    }
  }

  const d3ClubLink = useD3Link()

  const hideNavItem = (name: string) => {
    if (
      !(user?.isPhoneVerified && user?.phoneIsoCode === IsoCodes.INDIA) &&
      name === SideMenuItemsEnum.Pan_Card
    ) {
      return true
    }
    return false
  }
  // const platform = PLATFORM_ORIGIN === ThemeEnum.RARIO ? 'Rario' : 'Cricrush'

  return (
    <>
      <Sidenav
        height={['100vh', 'calc(100vh + 54px)']}
        width={'100%'}
        position={['fixed', 'absolute']}
        zIndex={9999990}
        top={[0, '-73px']}
        left={isMenuOpen ? 0 : '100%'}
        background={theme.colors.richBlackLight}
        backgroundPosition={'center'}
        backgroundSize="cover"
        overflowX="hidden"
        backgroundRepeat={'no-repeat'}
        pt={50}
        ref={SidenavRef}
      >
        <FlexBox width="100%" height="100%" flexDirection="column">
          {user && (
            <FlexBox width={'100%'} flexDirection={'column'} pb={40}>
              <FlexBox px={15} width={'100%'} alignItems="center" justifyContent={'space-between'}>
                <FlexBox
                  onClick={() => {
                    collapseAllMenus()
                    trackMenuClicks({
                      sub_category: 'homepage-menu_back_button_icon',
                    })
                  }}
                >
                  <LeftArrowIcon />
                </FlexBox>
                <FlexBox alignSelf={'flex-start'}>
                  {showWalletBox && <WalletBalanceBox closeMobileMenuModal={collapseAllMenus} />}
                  <LogoutButton
                    trackingPayload={{
                      sub_category: 'homepage-header_logout_icon',
                      click_type: 'icon',
                      click_action: 'trigger_logout_bottom_sheet',
                      category: 'homepage',
                    }}
                    flexBoxStyles={{
                      width: '40px',
                      ml: 10,
                      zIndex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </FlexBox>
              </FlexBox>
              {/* <FlexBox flexDirection={'column'} pt={20} width="60%">
                <FlexBox position={'relative'}>
                  <FlexBox
                    height={'22px'}
                    position="absolute"
                    left={0}
                    width="6px"
                    borderRadius={'0px 10px 10px 0px'}
                    background={theme.colors.secondary}
                  />
                  <TextField
                    content={`Explore ${platform}`}
                    fontSize={16}
                    lineHeight={'22px'}
                    fontWeight="600"
                    fontFamily={theme.fonts.primary}
                    letterSpacing={theme.letterSpacings.primary}
                    pl={15}
                  />
                </FlexBox>

                <TextField
                  pl={15}
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  fontWeight="400"
                  opacity={0.6}
                >
                  Get to know the ins and outs of the {getPlatformName()} ecosystem
                </TextField>
              </FlexBox> */}
              {MOBILE_ROUTES.map(({ title = '', menu = [] }, i) => {
                return (
                  <FlexBox px={15} key={title + i} flexDirection={'column'} pt={20}>
                    <TextField
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      fontWeight="600"
                      fontFamily={theme.fonts.primary}
                      letterSpacing={theme.letterSpacings.primary}
                      opacity={0.6}
                      pb={0}
                    >
                      {title}
                    </TextField>
                    {menu.length > 0 &&
                      menu.map(
                        (
                          { link, name = '', subtitle = '', icon, newTab = false, cr = true },
                          k
                        ) => {
                          if (PLATFORM_ORIGIN === ThemeEnum.CR && !cr) {
                            return <></>
                          }
                          if (name === SideMenuItemsEnum.Currency_Preference) {
                            // TODO Currency Toggle
                            return <MenuCurrencyToggle key={name + k} />
                          } else if (hideNavItem(name) || !link)
                            return <Fragment key={name + k}></Fragment>
                          return newTab ? (
                            <Fragment key={name + k}>
                              <MobileHeaderItem
                                name={name}
                                subtitle={subtitle}
                                icon={icon}
                                getActiveIcon={getActiveIcon}
                                onItemClick={() =>
                                  newTab
                                    ? handelNewWindownclick(name, link)
                                    : handleNavMenuClick(name)
                                }
                              />
                            </Fragment>
                          ) : (
                            <Fragment key={name + k}>
                              <Link href={name === 'About D3.Club' ? d3ClubLink : link!} passHref>
                                <a>
                                  <MobileHeaderItem
                                    name={name}
                                    subtitle={subtitle}
                                    icon={icon}
                                    getActiveIcon={getActiveIcon}
                                    onItemClick={() =>
                                      newTab
                                        ? handelNewWindownclick(name, link)
                                        : handleNavMenuClick(name)
                                    }
                                  />
                                </a>
                              </Link>
                            </Fragment>
                          )
                        }
                      )}
                    {/* {!title && (
                      <FlexBox
                        mt="5px"
                        background={'rgba(255, 255, 255, 0.15)'}
                        height={1}
                        width="100%"
                      />
                    )} */}
                  </FlexBox>
                )
              })}
              <TrustedBy />
              {mobileAppBaseConfigData && (
                <TextField
                  py={25}
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  fontWeight="400"
                  opacity={0.6}
                  pb={0}
                  pl={'5px'}
                  textAlign="center"
                >
                  Version {mobileAppBaseConfigData?.appVersion}
                </TextField>
              )}
            </FlexBox>
          )}
        </FlexBox>
      </Sidenav>
    </>
  )
}

export default MobileHeader
